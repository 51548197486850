import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

//NOTE: new routes import write here:
import AuthLogin from 'components/auth/login/Login';
import AuthRegister from 'components/auth/register/Register';
import AuthResetPassword from 'components/auth/reset-password/ResetPassword';
import AuthLogout from 'components/auth/Logout';
import UserProfile from 'components/profile/_/user/UserProfile';
import TeamProfile from 'components/profile/_/team/TeamProfile';
import Notifications from 'components/notification/Notifications.view';
import Portal from 'components/employeePortal/EmployeePortal';
import Battles from 'components/battles/Battles.view';
import Challenge from 'components/challenge/challenge';
import ChallengeList from 'components/challenge/_/list/ChallengeList.view';
import ContactList from 'components/contact-list/ContactList.view';
import MissionList from 'components/mission/_/list/MissionList.view';
import AchievementList from 'components/achievement/list/AchievementList.view';
import StoreItemDetails from 'components/store/item/details/Details';
import Store from 'components/store/item/list/ItemList.view';
import Cafeteria from 'components/cafeteria/Cafeteria.view';
import CafeteriaItemDetails from 'components/cafeteria/item-details/CefeteriaItemDetails.view';
import NewsList from 'components/news/list/NewsList.view';
import RankingList from 'components/ranking/_/RankingList';

import CreativeChallengeSubmissionDetails from 'components/challenge/_/challenge/creative/submission/details/Details';

import FAQ from 'components/game/FAQ.view';

import AnonymousOnly from 'components/auth/AnonymousOnly';

import Layout1 from 'components/layouts/Layout1';
import { buildLayout2 } from 'components/layouts/Layout2';
import { buildLayout3 } from 'components/layouts/Layout3';

import Init from 'components/_/app/init/Init';
import InitMaster from 'components/_/app/init/InitMaster';
import SetAccessToken from 'components/application/setAccessToken';

export default function(routing, applicationService, masterService, browserHistory) {
	return () => (
		<Init>
			{() => {
				const integrations = applicationService.applicationConfig().integrations();

				return (
					<Router history={browserHistory}>
						<Switch>
							<Route exact path="/setAccessToken/:accessToken?" render={SetAccessToken} />
							<Route
								path="/auth/login"
								render={() => (
									<Layout1>
										<AnonymousOnly>
											{integrations.loginIntegrations.map(integration => (
												<Route
													key={integration.id}
													path={`/auth/login/${integration.id}`}
													component={props => (
														<AuthLogin
															{...props}
															params={{
																...props.match.params,
																id: integration.id,
															}}
														/>
													)}
												/>
											))}
											<Route
												exact
												path="/auth/login"
												render={props => (
													<routing.LandingPage>
														<AuthLogin {...props} />
													</routing.LandingPage>
												)}
											/>
										</AnonymousOnly>
									</Layout1>
								)}
							/>
							<Route
								path="/redirect/auth/oauth/:id/authorize-callback"
								component={({ location, match }) => (
									<Redirect to={`/auth/login/${match.params.id}${location.search}`} />
								)}
							/>

							<Route
								path="/auth/register"
								render={() => (
									<Layout1>
										<AnonymousOnly>
											{integrations.registerIntegrations.map(integration => (
												<Route
													key={integration.id}
													path={`/auth/register/${integration.id}`}
													component={props => (
														<AuthRegister
															{...props}
															params={{
																...props.match.params,
																id: integration.id,
															}}
														/>
													)}
												/>
											))}
											<Route
												exact
												path="/auth/register"
												render={props => (
													<routing.LandingPage>
														<AuthRegister {...props} />
													</routing.LandingPage>
												)}
											/>
										</AnonymousOnly>
									</Layout1>
								)}
							/>
							<Route
								path="/redirect/auth/:type/:id/register"
								component={({ location, match }) => (
									<Redirect to={`/auth/register/${match.params.id}${location.search}`} />
								)}
							/>

							<Route
								path="/auth/reset/:id"
								render={props => (
									<Layout1>
										<AnonymousOnly>
											<AuthResetPassword {...props} />
										</AnonymousOnly>
									</Layout1>
								)}
							/>
							<Route
								path="/redirect/auth/:type/:id/reset"
								component={({ location, match }) => (
									<Redirect to={`/auth/reset/${match.params.id}${location.search}`} />
								)}
							/>

							<Route path="/">
								<InitMaster>
									{() => (
										<Switch>
											<Redirect exact from="/" to={masterService.mainPage()} />

											<Route path="/auth/logout" component={AuthLogout} />

											{/* Layout2: */}
											<Redirect exact from="/mission/list" to="/mission/list/all" />
											<Route path="/mission/list/:tab?" render={buildLayout2(MissionList)} />
											<Route
												path="/mission/:id/details"
												render={buildLayout2(routing.MissionDetails)}
											/>

											<Route path="/news/list" render={buildLayout2(NewsList)} />
											<Route
												path="/news/:id/details"
												render={buildLayout2(routing.NewsDetails)}
											/>

											<Redirect exact from="/store" to="/store/all" />
											<Route exact path="/store/:tag?" render={buildLayout2(Store)} />

											<Redirect exact from="/achievement/list" to="/achievement/list/all" />
											<Route
												path="/achievement/list/:tag?"
												render={buildLayout2(AchievementList)}
											/>

											<Redirect exact from="/notification/list" to="/notification/list/all" />
											<Route
												path="/notification/list/:tab?"
												render={buildLayout2(Notifications)}
											/>

											<Route path="/ranking/list/:id?" render={buildLayout2(RankingList)} />

											<Route path="/challenge/list/:tab?" render={buildLayout2(ChallengeList)} />

											<Route path="/game/faq/:tab?" render={buildLayout2(FAQ)} />

											<Route exact path="/reward-cafeteria" render={buildLayout2(Cafeteria)} />

											<Redirect exact from="/contact/list" to="/contact/list/all" />
											<Route
												exact
												path="/contact/list/:tab?"
												render={buildLayout2(ContactList)}
											/>

											<Route
												exact
												path="/portal"
												render={buildLayout2(Portal, { hideMissionList: true })}
											/>

											<Redirect exact from="/battles" to="/battles/ongoing" />
											<Route
												exact
												path="/battles/:tab?"
												render={buildLayout2(Battles, { hideMissionList: true })}
											/>

											{/* Layout3: */}
											<Redirect
												exact
												from="/redirect/declaration-task-acceptance"
												to="/team/tasks"
											/>
											<Redirect exact from="/redirect/task/:id" to="/task/:id/details" />

											<Route
												path="/task/:id/details"
												render={buildLayout3(routing.TaskDetails)}
											/>
											<Route
												exact
												path="/store/item/:id/details"
												render={buildLayout3(StoreItemDetails)}
											/>
											<Route
												path="/achievement/:id/details"
												render={buildLayout3(routing.AchievementDetails)}
											/>
											<Route
												path="/challenge/submission/:id/details"
												render={buildLayout3(CreativeChallengeSubmissionDetails)}
											/>
											<Route path="/challenge/:id/:view?" render={buildLayout3(Challenge)} />
											<Route exact path="/user/:tab" render={buildLayout3(UserProfile)} />
											<Route exact path="/user/:id/:tab" render={buildLayout3(UserProfile)} />
											<Route exact path="/team/:tab" render={buildLayout3(TeamProfile)} />
											<Route exact path="/team/:id/:tab" render={buildLayout3(TeamProfile)} />
											<Route
												exact
												path="/reward-cafeteria/item/:id"
												render={buildLayout3(CafeteriaItemDetails)}
											/>
										</Switch>
									)}
								</InitMaster>
							</Route>
						</Switch>
					</Router>
				);
			}}
		</Init>
	);
}
