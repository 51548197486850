import APIService from 'services/APIService';
import Battle, { BattleDTO, BattleStatus } from '../../models/battles/Battle';

export default class BattlesService {
	constructor(private readonly ApiService: APIService) {}

	fetchBattleList(limit: number, statuses?: BattleStatus[]) {
		return this.ApiService.get<{ items: BattleDTO[]; count: number; waiting?: number; ongoing?: number }>(
			`/teambooster/battles/my-battles`,
			{ limit, statuses: !!statuses?.length ? statuses.join(',') : undefined },
			undefined,
			{
				version: 'v2',
				controller: 'workflows',
			}
		).then(response => ({
			...response,
			items: response.items.map(battleDTO => this.toModel(battleDTO)),
		}));
	}

	fetchBattle(battleId: string): Promise<Battle> {
		return this.ApiService.get<BattleDTO>(`/teambooster/battles/battle/${battleId}`, undefined, undefined, {
			version: 'v2',
			controller: 'workflows',
		}).then(response => this.toModel(response));
	}

	toModel(battleDTO: BattleDTO): Battle {
		return new Battle(battleDTO);
	}
}
