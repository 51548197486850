import BattlePlayer, { BattlePlayerDTO } from './BattlePlayer';
import { Moment } from 'moment';
import moment from 'moment/moment';

export enum BattleStatus {
	WAITING = 'waiting',
	ACCEPTED = 'accepted',
	ONGOING = 'ongoing',
	DECLINED = 'declined',
	CANCELED = 'canceled',
	RESOLVED = 'resolved',
}

export interface BattleDTO {
	id: string;
	name: string;
	description: string | undefined;
	players: BattlePlayerDTO[];
	start_date: string;
	end_date: string;
	resolve_date: string;
	updated_at: string;
	status: BattleStatus;
}

export default class Battle {
	private _id: string;
	private _name: string;
	private _description: string | undefined;
	private _players: BattlePlayer[];
	private _startDate: Moment;
	private _endDate: Moment;
	private _resolveDate: Moment;
	private _updatedAt: Moment;
	private _status: BattleStatus;

	constructor(battle: BattleDTO) {
		this._id = battle.id;
		this._name = battle.name;
		this._description = battle.description;
		this._players = battle.players.map(playerDTO => new BattlePlayer(playerDTO));
		this._startDate = moment(battle.start_date);
		this._endDate = moment(battle.end_date);
		this._resolveDate = moment(battle.resolve_date);
		this._updatedAt = moment(battle.updated_at);
		this._status = battle.status;
	}

	get id() {
		return this._id;
	}

	get name() {
		return this._name;
	}

	get description() {
		return this._description;
	}

	get players() {
		return this._players;
	}

	get startDate() {
		return this._startDate;
	}

	get endDate() {
		return this._endDate;
	}

	get resolveDate() {
		return this._resolveDate;
	}

	get updatedAt() {
		return this._updatedAt;
	}

	get status() {
		return this._status;
	}
}
