import React, { useState } from 'react';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import find from 'lodash/find';

import useStyles from './BuyModal.form.jss';

import { Digital } from './address/digital/Digital';
import { DigitalSchema } from './address/digital/Digital.schema';

import { Physical } from './address/physical/Physical';
import { PhysicalSchema } from './address/physical/Physical.schema';

import { Select } from 'components/_/form/select/Select';

export const BuyModalForm = injectIntl(({ item, addresses, userProfile, success, onSubmit, intl }) => {
	const classes = useStyles();
	const initialValues = {
		digital: {
			type: 'digital',
			forename: userProfile.forename(),
			surname: userProfile.surname(),
			email: userProfile.email(),
			reEmail: userProfile.email(),
		},
		physical: {
			type: 'physical',
			forename: userProfile.forename(),
			surname: userProfile.surname(),
			companyName: '',
			streetName: '',
			buildingNumber: '',
			flatNumber: '',
			postalCode: '',
			city: '',
			phone: '',
			email: userProfile.email(),
			reEmail: userProfile.email(),
			note: '',
		},
	};
	const schema = {
		digital: DigitalSchema,
		physical: PhysicalSchema,
	};
	const component = {
		digital: Digital,
		physical: Physical,
	};
	const AddressForm = component[item.deliveryType];

	const [addressSelect, setAddressSelect] = useState(addresses[0] ? addresses[0] : 'create');

	return (
		<Formik
			enableReinitialize
			initialValues={{
				addressSelect,
				address:
					addressSelect === 'create'
						? initialValues[item.deliveryType]
						: { ...addressSelect.address, reEmail: addressSelect.address.email },
			}}
			initialStatus={{ message: null, error: null }}
			validationSchema={Yup.object().shape({
				addressSelect: Yup.string(),
				address: Yup.object().when('addressSelect', {
					is: 'create',
					then: () => schema[item.deliveryType],
				}),
			})}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid, status }) =>
				status.message === 'bought' ? (
					<div className={classnames('p-4 text-center', classes.bought)}>{success}</div>
				) : (
					<Form className="form text-left">
						<div className={classnames('px-2 py-2 px-sm-4 py-sm-3', classes.modal)}>
							<div className="px-2 pt-2 px-sm-4 pt-sm-4">
								<h3 className="text-center mb-3">Twoje dane teleadresowe</h3>
								<Select
									name="addressSelect"
									onChange={event =>
										setAddressSelect(
											find(addresses, address => address.id === event.target.value) || 'create'
										)
									}
									list={[
										...addresses.map(address => ({
											value: address.id,
											content: address.envelope_text,
										})),
										{
											value: 'create',
											content: intl.formatMessage({
												id: 'prize-details.content.buy-item.address.create',
											}),
										},
									]}
								/>
								<fieldset disabled={addressSelect !== 'create'}>
									<AddressForm />
								</fieldset>
							</div>
						</div>
						<div
							className={classnames(
								'd-flex flex-column align-items-center justify-content-center py-4 rounded-bottom',
								classes.footer
							)}
						>
							{status.error ? (
								<FormattedMessage id={`cafeteria.error.${status.error}`}>
									{message => <div className="error mb-3">{message}</div>}
								</FormattedMessage>
							) : null}
							<button
								type="submit"
								className={classnames('btn__basic', {
									'btn__basic--disabled': !isValid || status.error,
									loading: isSubmitting,
								})}
								disabled={!isValid || isSubmitting || status.error}
							>
								<FormattedMessage id="prize-details.button.buy-now" tagName="span" />
							</button>
						</div>
					</Form>
				)
			}
		</Formik>
	);
});
