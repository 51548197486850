import { useInfiniteQuery } from 'react-query';
import { BattleStatus } from 'models/battles/Battle';
import BattlesService from 'services/battles/BattlesService';

export type UseGetBattleListProps = {
	'services.battlesService': BattlesService;
	statuses?: BattleStatus[];
};

const countPerPage = 18;

export type UseGetBattleListQueryReturn = ReturnType<typeof useGetBattleList>;

export const useGetBattleList = (props: UseGetBattleListProps) => {
	const battlesService = props['services.battlesService'];

	return useInfiniteQuery({
		queryKey: ['battles-my-battle-list', props.statuses],
		queryFn: () => battlesService.fetchBattleList(countPerPage, props.statuses),
		getNextPageParam: (lastPage, pages) => Math.ceil(lastPage.count / countPerPage) > pages.length,
	});
};

export default useGetBattleList;
