import React from 'react';
import { BattlesListItemProps } from './BattlesListItemType';
import { BattleListItemPlayers } from './common/Players';
import { FormattedMessage } from 'react-intl';

export type BattlesListItemOngoingProps = BattlesListItemProps;

export const BattlesListItemWaiting = (props: BattlesListItemOngoingProps) => {
	return (
		<div className="col-12 col-md-8 col-lg-6 col-xl-4 mb-4 mb-sm-5">
			<div className="tile battlesList__tile fs-4 rounded h-100 p-3 d-flex flex-column">
				<div className="font-weight-bold mx-auto mb-3 text-center">{props.battle.name}</div>
				<BattleListItemPlayers {...props} />

				<div className="text-center tile__completed">
					<p className="tile__color--gray mb-1">
						<FormattedMessage id={`battle-list.status.waiting.startAt`} />
					</p>
					<p className="font-weight-bold">
						{props.battle.startDate.format('L')}
						{', '}
						{props.battle.startDate.format('LT')}
					</p>
				</div>
			</div>
		</div>
	);
};

export default BattlesListItemWaiting;
