import InternalStaticAnchorService from 'services/application/anchor/subServices/internalStatic/InternalStaticAnchorService';
import subServices from 'services/application/anchor/subServices/internalStatic/subServices';

export default {
	'services.internalStaticAnchorService': {
		class: InternalStaticAnchorService,
		calls: [
			{
				name: 'registerSubService',
				args: [
					'achievement-list',
					{
						$ref: 'services.internalStaticAnchorAchievementListService',
					},
				],
			},
			{
				name: 'registerSubService',
				args: [
					'challenge-list',
					{
						$ref: 'services.internalStaticAnchorChallengeListService',
					},
				],
			},
			{
				name: 'registerSubService',
				args: ['contact-list', { $ref: 'services.internalStaticAnchorContactListService' }],
			},
			{
				name: 'registerSubService',
				args: ['portal', { $ref: 'services.internalStaticAnchorPortalService' }],
			},
			{
				name: 'registerSubService',
				args: ['battles', { $ref: 'services.internalStaticAnchorBattlesService' }],
			},
			{
				name: 'registerSubService',
				args: ['faq', { $ref: 'services.internalStaticAnchorFAQService' }],
			},
			{
				name: 'registerSubService',
				args: ['login', { $ref: 'services.internalStaticAnchorLoginService' }],
			},
			{
				name: 'registerSubService',
				args: ['logout', { $ref: 'services.internalStaticAnchorLogoutService' }],
			},
			{
				name: 'registerSubService',
				args: ['task-list', { $ref: 'services.internalStaticAnchorMissionListService' }],
			},
			{
				name: 'registerSubService',
				args: ['news-list', { $ref: 'services.internalStaticAnchorNewsListService' }],
			},
			{
				name: 'registerSubService',
				args: [
					'notification-list',
					{
						$ref: 'services.internalStaticAnchorNotificationListService',
					},
				],
			},
			{
				name: 'registerSubService',
				args: ['item-list', { $ref: 'services.internalStaticAnchorPrizeListService' }],
			},
			{
				name: 'registerSubService',
				args: ['ranking-list', { $ref: 'services.internalStaticAnchorRankingListService' }],
			},
			{
				name: 'registerSubService',
				args: ['reward-cafeteria', { $ref: 'services.internalStaticAnchorRewardCafeteriaService' }],
			},
			{
				name: 'registerSubService',
				args: ['game-rules', { $ref: 'services.internalStaticAnchorRulesService' }],
			},
			{
				name: 'registerSubService',
				args: ['team-profile', { $ref: 'services.internalStaticAnchorTeamProfileService' }],
			},
			{
				name: 'registerSubService',
				args: ['user-profile', { $ref: 'services.internalStaticAnchorUserProfileService' }],
			},
			//TODO v-card
		],
	},
	...subServices,
};
